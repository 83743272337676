@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --primary-color: #0094ff;
  --secondary-color: #081620;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

.responsiveTable .ant-table {
  overflow-x: scroll;
}

.ant-table-cell{
  font-size: 1rem !important;
}

.modal_bg{
  height: 100vh;
  position: fixed;
  left: 0%;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  z-index: 200000000000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.modal_bg .modal{
  display: contents;
  background-color: white;
  z-index: 200000000000 !important;
  font-size: 1rem
}

.modal_bg .modal-dialog{
max-width: 70% !important;
}

.pending {
  background: rgb(252, 141, 141);
  color: rgb(179 0 0);
}

.process {
  background: #d9eeff;
  color: #0958d9;
}
.success {
  background: rgb(186 250 184);
  color: rgb(5, 146, 0);
}

.yellow{
  background-color: rgb(226, 226, 0) !important;
}


.red {
  background: rgb(252, 141, 141);
  color: rgb(179 0 0);
}

.blue {
  background: #d9eeff;
  color: #0958d9;
}

.green {
  background: rgb(186 250 184);
  color: rgb(5, 146, 0);
}

.yellow {
  background: rgb(244, 252, 173);
  color: rgb(151, 151, 1);
}


.details{
  background: white;
  width: 70%;
  padding: 30px 20px;
  border-radius: 10px;
}

th,
td {
  white-space: nowrap;
}

.Chat-section {
  height: calc(100vh - 115px);
}

.avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-position: center !important;
  background-size: cover !important;
  background-color: gainsboro;
}

.sm-avatar {
  height: 40px;
  width: 40px;
}

.user-chat {
  cursor: pointer;
}

.chat-info {
  width: calc(100% - 60px);
}

.msg {
  width: fit-content;
}

.msg_scrollable{
  height: 60vh;
  overflow-y: scroll;
}

.truncate {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  background: #fff;
}

.chats_list {
  height: 100%;
}

.chat-convo {
  position: relative;
}

.send {
  position: absolute;
  width: 98%;
  top: calc(100% - 50px);
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px !important;
}

.btn_primary {
  border: 0;
  outline: none;
  padding: 10px;
  background: var(--primary-color);
}

.bg-blue {
  background: var(--primary-color);
  color: white;
}

.bg-gray {
  background: gainsboro;
}

.h-100vh {
  height: 100vh;
}

.bg_status{
  position: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.status_modal{
  width: 80%;
  padding: 30px;
  border-radius: 20px;
  background-color: white;
}

.status_box{
  height: 200px;
  width: 32%;
  border-radius: 10px;
  font-size: 2rem;
}

.status_modal .red{
  background: #eb6b6b;
}

.status_modal .red:hover{
  background: #f05151;
}

.status_modal .yellow{
  background-color: rgb(226, 226, 0) !important;
}

.status_modal .blue{
  background: #7373fd;
}

.status_modal .blue:hover{
  background: #5858f7;
}

.status_modal .green{
  background: #69ef69;
}

.status_modal .green:hover{
  background: #48ee48;
}

@media(max-width: 700px){
  .chats_list,
  .chat-convo{
    width: 100% !important;
  }
}